<template>
  <v-card class="segmented" flat>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h5>
            {{ $t('reporting.pdpReportName') }}
          </h5>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <div class="text-center" v-if="loading">
        <div class="text-uppercase muted mb-4">{{ $t('dataLoading') }}</div>
        <v-progress-linear indeterminate></v-progress-linear>
      </div>

      <div v-else>
        <status-chart
          :chart-data="statusReport"
          :process-type="2"
        ></status-chart>

        <div class="text-right mt-4">
          <v-btn @click="downloadReport" :loading="downloading">
            <v-icon class="mr-2">mdi-file-excel-outline</v-icon>
            {{ $t('reporting.download') }}
          </v-btn>
        </div>

        <status-report-table
          :headers="headers"
          :report-rows="reportRows"
          :isPdpReport="true"
        ></status-report-table>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import reportingService from '@/services/ReportingService.js';
import statusReportTable from '@/components/reporting/status-report-table.vue';
import statusChart from '@/components/reporting/status-chart.vue';

export default {
  data: () => ({
    loading: true,
    downloading: false,
    statusReport: null,
    reportData: null,
    options: {},
    series: []
  }),

  computed: {
    headers() {
      const data = [...this.reportData.result.columns];

      return data
        .filter((x) => x.showInUI)
        .sort((a, b) => a.sortIndex - b.sortIndex)
        .map((x) => {
          return {
            index: x.sortIndex,
            label: x.itemName,
            type: x.dataType
          };
        });
    },

    reportRows() {
      return [...this.reportData.result.rows].sort((a, b) => {
        // sort by last name
        if (a.columns[3].toLowerCase() > b.columns[3].toLowerCase()) {
          return 1;
        } else if (a.columns[3].toLowerCase() < b.columns[3].toLowerCase()) {
          return -1;
        } else {
          // and then by first name
          if (a.columns[4].toLowerCase() > b.columns[4].toLowerCase()) {
            return -1;
          } else if (a.columns[4].toLowerCase() < b.columns[4].toLowerCase()) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    }
  },

  async mounted() {
    await this.loadReport();
  },

  methods: {
    async downloadReport() {
      this.downloading = true;

      const data = await reportingService.downloadReport(2, 2, null, false);

      const blob = new Blob([data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      const fileUrl = window.URL.createObjectURL(blob);

      const anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.href = fileUrl;

      const processTypeLabel = this.$t(`feedbacks.processTypes.2`);
      const today = new Date().toISOString().slice(0, 10).replaceAll('-', '');
      anchor.download = `${today}_StatusReport_${processTypeLabel}.xlsx`;

      anchor.click();

      window.URL.revokeObjectURL(fileUrl);

      this.downloading = false;
    },

    async loadReport() {
      this.reportData = await reportingService.createReport(2, 2, null, false);

      this.statusReport = reportingService.getStatusReport(
        this.reportData.result.rows,
        9,
        [0, 110, 120, 199]
      );

      this.loading = false;
    }
  },

  components: {
    statusReportTable,
    statusChart
  }
};
</script>
